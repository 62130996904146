import React from 'react';
import cx from 'classnames';

import logo from './../../assets/img/logo.png';

import estilos from './estilos.module.css';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <Navbar className={cx(estilos.navbar)} expand="lg" variant="dark">
            <Navbar.Brand href="#home">
                <img src={logo} alt="next-technologies logo"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={cx("ml-auto")}>
                    <Nav.Link as="div"><Link to="/usuarios" className={cx(estilos.link, "text-white")}>Usuarios</Link></Nav.Link>
                    <Nav.Link as="div"><Link to="/globales" className={cx(estilos.link, "text-white")}>Globales</Link></Nav.Link>
                    <Nav.Link as="div"><Link to="/portales" className={cx(estilos.link, "text-white")}>Portales</Link></Nav.Link>
                    <Nav.Link as="div"><Link to="/correos" className={cx(estilos.link, "text-white")}>Correos</Link></Nav.Link>
                    <Nav.Link as="div"><Link to="/busquedaFacturas" className={cx(estilos.link, "text-white")}>Búsqueda</Link></Nav.Link>
                    <Nav.Link as="div"><Link to="/cancelacionMasiva" className={cx(estilos.link, "text-white")}>Cancelación Masiva</Link></Nav.Link>
                    <Nav.Link as="div"><Link to="/reportesUsuarios" className={cx(estilos.link, "text-white")}>Reportes</Link></Nav.Link>
                    <Nav.Link as="div"><Link to="/bitacora" className={cx(estilos.link, "text-white")}>Logs</Link></Nav.Link>
                    <Nav.Link as="div"><Link to="/busquedaCFDI" className={cx(estilos.link, "text-white")}>Solicitudes Cancelación</Link></Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header;