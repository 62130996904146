
import React, { Fragment } from 'react';
import './App.css';
import AppMain from './AppMain';
import { Route } from 'react-router';

const App: React.FC = () => {
  return (
    <div className="App">
      <Fragment>
        <Route path="/" render={() => (<AppMain />)} />
      </Fragment>
    </div>
  );
}

export default App;