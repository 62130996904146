import React, {Fragment} from 'react';

import Header from '../header';
import AltNav from '../AltNav';

const Top = () => {
    return (
       <Fragment>
           <Header />
           <AltNav />
       </Fragment>
    )
}

export default Top;