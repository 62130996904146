import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import estilos from './estilos.module.css';

const AltNav = () => {
    //Agregar hooks para alternar un "active" class en los links cuando se seleccionan.
    return (
        <nav aria-label="breadcrumb" className={cx("float-right")}>
            <ol className={cx("breadcrumb", "bg-white")}>
                <li className={cx("breadcrumb-item", estilos.breadcrumb)}><Link to="/comunicados">Comunicados</Link></li>
                <li className={cx("breadcrumb-item", estilos.breadcrumb)}><Link to="/pacs">Pacs</Link></li>
                <li className={cx("breadcrumb-item", estilos.breadcrumb)}><Link to="/operacion-opera">Opera</Link></li>
                <li className={cx("breadcrumb-item", estilos.breadcrumb)}><Link to="/limpieza">Facturas y Series</Link></li>
            </ol>
        </nav>
    )
}

export default AltNav;