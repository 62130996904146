
import React, { lazy, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './App.css';

import Top from './statics/Top';

const Usuarios = lazy(() => import('./components/Usuarios'));
const CreacionUsuarios = lazy(() => import('./components/CreacionUsuarios'));
const Login = lazy(() => import('./pages/Login'));
const Comunicados = lazy(() => import('./components/Comunicados'));
const Pacs = lazy(() => import('./components/Pacs'));
const Opera = lazy(() => import('./components/Opera'));
const FacturasSeries = lazy(() => import('./components/FacturasSeries'));
const Globales = lazy(() => import('./components/Globales'));
const BuscarGlobales = lazy(() => import('./components/BuscarGlobales'));
const Portales = lazy(() => import('./components/Portales'));
const CrearPortal = lazy(() => import('./components/CrearPortal'));
const Correos = lazy(() => import('./components/Correos'));
const BusquedaFacturas = lazy(() => import('./components/BusquedaFacturas'));
const BusquedaTickets = lazy(() => import('./components/BusquedaTickets'));
const BusquedaFolios = lazy(() => import('./components/BusquedaFolios'));
const CancelMasiva = lazy(() => import('./components/CancelMasiva'));
const CancelExt = lazy(() => import('./components/CancelExt'));
const ReportesUsuarios = lazy(() => import('./components/ReportesUsuarios'));
const ReportesCentrosConsumo = lazy(() => import('./components/ReportesCentrosConsumo'));
const ReportesIntegraciones = lazy(() => import('./components/ReportesIntegraciones'));
const ReportesProveedores = lazy(() => import('./components/ReportesProveedores'));
const Logs = lazy(() => import('./components/Logs'));
const BusquedaCFDI = lazy(() => import('./components/SolicitudesCancel'));

const AppMain = () => {
    return (
        <Fragment>
            <Top />
            <Switch>
                <Route path="/usuarios" render={() => <Usuarios />} />
                <Route path="/nuevo-usuario" render={() => <CreacionUsuarios />} />
                <Route path="/login" render={() => <Login />} />
                <Route path="/comunicados" render={() => <Comunicados />} />
                <Route path="/pacs" render={() => <Pacs />} />
                <Route path="/operacion-opera" render={() => <Opera />} />
                <Route path="/limpieza" render={() => <FacturasSeries />} />
                <Route path="/globales" render={() => <Globales />} />
                <Route path="/buscarGlobales" render={() => <BuscarGlobales />} />
                <Route path="/portales" render={() => <Portales />} />
                <Route path="/crearPortal" render={() => <CrearPortal />} />
                <Route path="/correos" render={() => <Correos />} />
                <Route path="/busquedaFacturas" render={() => <BusquedaFacturas />} />
                <Route path="/busquedaTickets" render={() => <BusquedaTickets />} />
                <Route path="/busquedaFolios" render={() => <BusquedaFolios />} />
                <Route path="/cancelacionMasiva" render={() => <CancelMasiva />} />
                <Route path="/cancelacionMasivaExterna" render={() => <CancelExt />} />
                <Route path="/reportesUsuarios" render={() => <ReportesUsuarios />} />
                <Route path="/reportesCentrosConsumo" render={() => <ReportesCentrosConsumo />} />
                <Route path="/reportesIntegraciones" render={() => <ReportesIntegraciones />} />
                <Route path="/reportesProveedores" render={() => <ReportesProveedores />} />
                <Route path="/bitacora" render={() => <Logs />} />
                <Route path="/busquedaCFDI" render={() => <BusquedaCFDI />} />
                <Route path="/**" exact render={() => (<Redirect to='/usuarios' />)} />
            </Switch>
        </Fragment>
    );
}

export default AppMain;

